import React from 'react';

const TradesTable = ({ todayData }) => (
  <div className="table-trades">
    <table className="table-with-scroll">
      <thead>
        <tr>
          <th className="text-center">Hora</th>
          <th className="text-center">Precio</th>
          <th className="text-center">Volumen</th>
        </tr>
      </thead>
      <tbody className="scrollable-tbody">
        {todayData.length === 0 ? (
          <tr>
            <td colSpan="3" className="text-center">
              <div className="no-data-message">No se realizaron operaciones aún.</div>
            </td>
          </tr>
        ) : (
          [...todayData].reverse().map((trade, index) => (
            <tr key={index}>
              <td>{new Date(parseInt(trade.servertime)).toLocaleTimeString()}</td>
              <td>{trade.price}</td>
              <td>{trade.size}</td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  </div>
);

export default React.memo(TradesTable);