import React from 'react';

const BookOffersTable = ({ bids, offers }) => (
  <div className="table-offers">
    <table className="table-with-scroll">
      <thead>
        <tr>
          <th className="text-center">Compra</th>
          <th className="text-center">Venta</th>
        </tr>
      </thead>
      <tbody className="scrollable-tbody">
        <tr>
          <td>
            {bids?.length ? (
              <table>
                <thead>
                  <tr>
                    <th className="text-center">Precio</th>
                    <th className="text-center">Tamaño</th>
                  </tr>
                </thead>
                <tbody>
                  {bids.map((item, index) => (
                    <tr key={index}>
                      <td>{item.price}</td>
                      <td>{item.size}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="no-data-message">Sin ofertas</div>
            )}
          </td>
          <td>
            {offers?.length ? (
              <table>
                <thead>
                  <tr>
                    <th className="text-center">Precio</th>
                    <th className="text-center">Tamaño</th>
                  </tr>
                </thead>
                <tbody>
                  {offers.map((item, index) => (
                    <tr key={index}>
                      <td>{item.price}</td>
                      <td>{item.size}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="no-data-message">Sin ofertas</div>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default React.memo(BookOffersTable);