import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import './auth.css';
import { Eye, EyeSlash } from 'react-bootstrap-icons';

const Auth = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [showPassword, setShowPassword] = useState(false);

  const { loginUser, registerUser, alertMessage } = useAuth();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, lastname, email, password, confirmPassword } = formData;

    if (isLogin) {
      loginUser(email, password);
    } else {
      if (password !== confirmPassword) {
        alert('Las contraseñas no coinciden.');
        return;
      }
      registerUser(name, lastname, email, password);
    }
  };

  const toggleMode = () => {
    setIsLogin(!isLogin);
    setFormData({
      name: '',
      lastname: '',
      email: '',
      password: '',
      confirmPassword: '',
    });
  };

  return (
    <div className="auth-container">
      <div className={`auth-card ${isLogin ? 'login-card' : 'signup-card'}`}>
        {isLogin ? (
          <div className="auth-login-content">
            <h2>Iniciar Sesión</h2>
            <form onSubmit={handleSubmit} className="auth-form">
              <InputField
                name="email"
                type="email"
                placeholder="Correo Electrónico"
                value={formData.email}
                onChange={handleChange}
              />
              <PasswordField
                name="password"
                placeholder="Contraseña"
                value={formData.password}
                onChange={handleChange}
                showPassword={showPassword}
                toggleVisibility={togglePasswordVisibility}
              />
              {alertMessage && <p className="alert-message">{alertMessage}</p>}
              <button type="submit" className="button button-primary">
                Iniciar Sesión
              </button>
            </form>
            <div className="switch-container">
              <button onClick={toggleMode} className="button button-link">
                ¿No tienes una cuenta? Regístrate
              </button>
            </div>
          </div>
        ) : (
          <div className="auth-signup-content">
            <h2>Registrarse</h2>
            <form onSubmit={handleSubmit} className="auth-form">
              <InputField
                name="name"
                placeholder="Nombre"
                value={formData.name}
                onChange={handleChange}
              />
              <InputField
                name="lastname"
                placeholder="Apellido"
                value={formData.lastname}
                onChange={handleChange}
              />
              <InputField
                name="email"
                type="email"
                placeholder="Correo Electrónico"
                value={formData.email}
                onChange={handleChange}
              />
              <PasswordField
                name="password"
                placeholder="Contraseña"
                value={formData.password}
                onChange={handleChange}
                showPassword={showPassword}
                toggleVisibility={togglePasswordVisibility}
              />
              <PasswordField
                name="confirmPassword"
                placeholder="Confirmar Contraseña"
                value={formData.confirmPassword}
                onChange={handleChange}
                showPassword={showPassword}
                toggleVisibility={togglePasswordVisibility}
              />
              {alertMessage && <p className="alert-message">{alertMessage}</p>}
              <button type="submit" className="button button-primary">
                Registrarse
              </button>
            </form>
            <div className="switch-container">
              <button onClick={toggleMode} className="button button-link">
                ¿Ya tienes una cuenta? Inicia Sesión
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const InputField = ({ name, type = 'text', placeholder, value, onChange }) => (
  <div className="input-group">
    <input
      name={name}
      type={type}
      className="input-field"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      required
    />
  </div>
);

const PasswordField = ({ name, placeholder, value, onChange, showPassword, toggleVisibility }) => (
  <div className="input-group password-group">
    <input
      name={name}
      type={showPassword ? 'text' : 'password'}
      className="input-field"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      required
    />
    <span className="password-toggle" onClick={toggleVisibility}>
      {showPassword ? <EyeSlash size={20} /> : <Eye size={20} />}
    </span>
  </div>
);

export default Auth;