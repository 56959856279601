import React, { useEffect, useState } from 'react';
import { useModal } from '../context/ModalContext';
import { useChart } from '../context/ChartContext';
import { useDataWebSocket } from '../context/WebSocketContext';
import './chartHeader.css'
import { useStockUpdates, useStockValues } from '../context/StockContext';
import { useInterval } from '../context/IntervalContext';

const ChartHeader = React.memo(() => {

  const {
    handleShowModal
  } = useModal();

  const { updateData } = useChart();
  const { symbol } = useDataWebSocket();

  const { selectedInterval, setSelectedInterval } = useInterval();
  const { isFullScreen } = useStockValues();
  const { setIsFullScreen } = useStockUpdates();

  // Opciones de intervalo para el dropdown (etiqueta y valor)
  const intervals = [
    { label: '1 minuto', value: '1min' },
    { label: '5 minutos', value: '5min' },
    { label: '10 minutos', value: '10min' },
    { label: '15 minutos', value: '15min' },
    { label: '30 minutos', value: '30min' },
    { label: '1 hora', value: '1h' },
    { label: '2 horas', value: '2h' },
    { label: '4 horas', value: '4h' },
    { label: '6 horas', value: '6h' },
    { label: '12 horas', value: '12h' },
    { label: '1 día', value: '1d' },
  ];

  const handleSelectInterval = (interval) => {
    setSelectedInterval(interval);
    updateData(symbol, interval.value);
  };

  useEffect(() => {
    updateData(symbol, selectedInterval.value);
  });

  return (
    <div className="chart-header hex-container highcharts-stocktools-wrapper highcharts-bindings-container highcharts-bindings-wrapper">
      <div
        className={`chart-btn symbol-btn`}
        title="Seleccionar futuro"
        onClick={() => handleShowModal('SelectFutureModal')}
      >
        <img src="/icons/symbol.png" className="button-icon" alt="Seleccionar Futuro" />
        <span>{symbol}</span>
      </div>

      <div className="dropdown-interval">
        <div
          className="chart-btn interval-btn"
          title="Seleccionar intervalo"
        >
          <img src="/icons/interval.png" className="button-icon" alt="Seleccionar Intervalo" />
          <span>{selectedInterval.label}</span>
        </div>
        <ul className="dropdown-menu">
          {intervals.map((interval, index) => (
            <li
              key={index}
              className="dropdown-item"
              onClick={() => handleSelectInterval(interval)}
            >
              {interval.label}
            </li>
          ))}
        </ul>
      </div>

      <div
        className={`chart-btn compare-btn`}
        title="Comparar futuros"
        onClick={() => handleShowModal('CompareFuturesModal')}
      >
        <img src="/icons/compare.png" className="button-icon" alt="Comparar Futuros" />
        <span>Comparar</span>
      </div>

      <div
        className={`chart-btn indicators-btn highcharts-indicators`}
        title="Indicators"
      >
        <img
          src="https://code.highcharts.com/gfx/stock-icons/indicators.svg"
          className="button-icon"
        />
        <span>Indicadores</span>
      </div>

      <div
        className="chart-btn colors-btn"
        title="Personalizar colores del gráfico"
        onClick={() => handleShowModal('ChangeColorsModal')}
      >
        <img src="/icons/colors.png" className="button-icon" alt="Personalizar Colores" />
        <span>Colores</span>
      </div>

      <div
        className={`chart-btn saved-charts-btn`}
        title="Gráficos Guardados"
        onClick={() => handleShowModal('SavedChartsModal')}
      >
        <img src="/icons/saved-charts.png" className="button-icon" alt="Gráficos Guardados" />
        <span>Tus gráficos</span>
      </div>

      <div
        className="hex-item save-chart-btn highcharts-save-chart"
        title="Guardar Gráfico"
      />
      <div
        className={`hex-item full-screen-btn ${isFullScreen === true ? 'active' : ''}`}
        title="Pantalla Completa"
        onClick={setIsFullScreen}
      />
    </div>
  );
});

export default React.memo(ChartHeader);