import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { storeDataInIndexedDB } from '../../utils/indexedDB';
import { useAuth } from './AuthContext';
import { useInterval } from './IntervalContext';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {

  const { user } = useAuth();
  const { selectedInterval } = useInterval();

  const ws = useRef(null);
  const [connected, setConnected] = useState(false);
  const [symbol, setSymbol] = useState('RFX20/DIC24');
  const [todayData, setTodayData] = useState([]);
  const [marketData, setMarketData] = useState([]);
  const [futuresList, setFuturesList] = useState([]);
  const [currentSubscription, setCurrentSubscription] = useState({ symbol: null, interval: null }); // Estado para rastrear la suscripción actual

  // Función para conectar al WebSocket con reconexión automática
  useEffect(() => {
    const connectWebSocket = () => {
      if (!user || ws.current || connected) return; // Solo conectar si el usuario está autenticado y no conectado.

      ws.current = new WebSocket(process.env.REACT_APP_FLY_URL_WB);

      console.log('Conectando a WebSocket:', process.env.REACT_APP_FLY_URL_WB);

      ws.current.onopen = () => {
        console.log('Conectado al WebSocket');
        setConnected(true);
        sendSubscription(symbol, selectedInterval.value); // Suscripción inicial al conectarse.
      };

      ws.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        handleWebSocketMessage(message);
      };

      ws.current.onclose = () => {
        console.log('Desconectado del WebSocket');
        setConnected(false);
        ws.current = null; // Resetear la referencia del WebSocket.

        // Intentar reconexión después de un tiempo
        setTimeout(connectWebSocket, 5000);
      };

      ws.current.onerror = (error) => {
        console.error('Error en WebSocket:', error);
        setConnected(false);
      };
    };

    connectWebSocket();

    return () => {
      if (ws.current) {
        ws.current.close();
        ws.current = null;
      }
    };
  }, [user]); // Reconectar solo si cambia el usuario

  // Enviar mensaje de suscripción al WebSocket cuando cambie el símbolo o el intervalo
  useEffect(() => {
    if (connected && ws.current && ws.current.readyState === WebSocket.OPEN) {
      sendSubscription(symbol, selectedInterval.value);
    } else {
      console.log('El WebSocket no está listo para enviar la suscripción.');
    }
  }, [symbol, selectedInterval, connected]);

  // Función para enviar la suscripción al WebSocket
  const sendSubscription = (symbol, interval) => {
    // Verificar si la suscripción actual ya es la misma
    if (currentSubscription.symbol === symbol && currentSubscription.interval === interval) {
      console.log(`Ya estás suscrito a símbolo=${symbol}, intervalo=${interval}. No se enviará un mensaje duplicado.`);
      return;
    }

    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(
        JSON.stringify({
          type: 'subscribe',
          symbol,
          interval,
        })
      );
      console.log(`Mensaje de suscripción enviado: símbolo=${symbol}, intervalo=${interval}`);
      setCurrentSubscription({ symbol, interval }); // Actualizar el estado de la suscripción
    } else {
      console.warn('No se pudo enviar el mensaje porque el WebSocket aún no está conectado.');
    }
  };

  // Manejar mensajes recibidos del WebSocket
  const handleWebSocketMessage = async (message) => {
    console.log('Mensaje recibido:', message);

    switch (message.type) {
      case 'ohlc_data':
        await storeDataInIndexedDB(`data_${message.interval}`, message.symbol, message.data);
        break;
      case 'today_trades':
        setTodayData(message.data);
        break;
      case 'market_data':
        setMarketData(message.data);
        break;
      case 'futures_list':
        setFuturesList(message.data);
        break;
      default:
        console.log('Tipo de mensaje desconocido:', message);
        break;
    }
  };

  return (
    <WebSocketContext.Provider
      value={{
        connected,
        symbol,
        setSymbol,
        todayData,
        marketData,
        futuresList
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export const useDataWebSocket = () => useContext(WebSocketContext);