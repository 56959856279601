import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Auth from './components/auth/Auth';
import ChartHigh from './components/chart/ChartHigh';
import Navbar from './components/navbar/Navbar';
import TablesContainer from './components/tables/TablesContainer';
import ModalManager from './components/modals/ModalManager';
import Loading from './components/chart/Loading';

import { AuthProvider, useAuth } from './components/context/AuthContext';
import { ThemeProvider } from './components/context/ThemeContext';
import { ModalProvider } from './components/context/ModalContext';
import { ChartProvider } from './components/context/ChartContext';
import { WebSocketProvider } from './components/context/WebSocketContext';
import { StockProvider } from './components/context/StockContext';
import { IntervalProvider } from './components/context/IntervalContext';

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppWrapper />
      </AuthProvider>
    </Router>
  );
}

function AppWrapper() {
  const { user } = useAuth();

  // Mostrar la pantalla de autenticación si no hay un usuario autenticado
  if (!user) {
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/auth" />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="*" element={<Navigate to="/auth" />} />
      </Routes>
    );
  }

  // Renderizar los contextos y componentes dependientes del usuario autenticado
  return (
    <ThemeProvider>
      <IntervalProvider>
        <ChartProvider>
          <ModalProvider>
            <StockProvider>
              <WebSocketProvider>
                <AppContent />
              </WebSocketProvider>
            </StockProvider>
          </ModalProvider>
        </ChartProvider>
      </IntervalProvider>
    </ThemeProvider>
  );
}

function AppContent() {
  const { user, handleLogout } = useAuth();

  return (
    <div className="App">
      <Navbar user={user} handleLogout={handleLogout} />
      <div className="main-container">
        <ChartHigh />
        <TablesContainer />
      </div>
      <Routes>
        <Route path="/" element={<Navigate to="/futuros" />} />
        <Route path="*" element={<Navigate to="/futuros" />} />
      </Routes>
      <ModalManager />
    </div>
  );
}

export default App;