import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useChart } from '../context/ChartContext';

// Subcomponente memoizado para manejar la selección de colores
const ColorPicker = React.memo(({ label, initialColor, onChange }) => {
  const [color, setColor] = useState(initialColor);

  const handleColorChange = (value) => {
    setColor(value);
    onChange(label, value);
  };

  useEffect(() => {
    setColor(initialColor);
  }, [initialColor]);

  return (
    <Form.Group controlId={label}>
      <Form.Label>{label.charAt(0).toUpperCase() + label.slice(1)}</Form.Label>
      <Form.Control
        type="color"
        value={color}
        onChange={(e) => handleColorChange(e.target.value)}
      />
    </Form.Group>
  );
});

// Componente genérico para modales
const GenericModal = ({
  title,
  show,
  onClose,
  onAction,
  actionLabel = 'Guardar',
  items = [],
  selectedItems = [],
  onSelectItem,
  content,
  initialColors = {}, // Configuración inicial para el selector de colores
}) => {
  const { updateColor } = useChart();

  // Manejar cambio de color global
  const handleColorChange = useCallback(
    (field, value) => {
      updateColor(field, value);
    },
    [updateColor]
  );

  return (
    <Modal
      show={show}
      onHide={onClose}
      dialogClassName="modal-dialog-centered"
      backdrop={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Renderizar el selector de colores si se pasan colores iniciales */}
        {Object.keys(initialColors).length > 0 && (
          <Form>
            {Object.entries(initialColors).map(([key, value]) => (
              <ColorPicker
                key={key}
                label={key}
                initialColor={value}
                onChange={handleColorChange}
              />
            ))}
          </Form>
        )}
        {/* Renderizar lista de items */}
        {items.length > 0 && (
          <div>
            {items.map((item, index) => (
              <div
                key={index}
                onClick={() => onSelectItem && onSelectItem(item)}
                style={{ cursor: 'pointer', padding: '5px' }}
              >
                {item}
              </div>
            ))}
          </div>
        )}
        {/* Renderizar lista de elementos seleccionados */}
        {selectedItems.length > 0 && (
          <div>
            <h5>Seleccionados:</h5>
            {selectedItems.map((item, index) => (
              <div key={index} style={{ padding: '5px', fontWeight: 'bold' }}>
                {item}
              </div>
            ))}
          </div>
        )}
        {/* Renderizar contenido adicional */}
        {content && <div>{content}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cerrar
        </Button>
        {onAction && (
          <Button variant="primary" onClick={onAction}>
            {actionLabel}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default GenericModal;