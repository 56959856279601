import React, { useState } from 'react';
import './navbar.css';
import UserProfileModal from '../modals/UserProfileModal.jsx';
import LogoutConfirmModal from '../modals/LogoutConfirmModal.jsx';
import { useTheme } from '../context/ThemeContext.js';

const Navbar = ({ user, handleLogout }) => {
  const { theme, toggleTheme } = useTheme();
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleProfileClick = () => {
    setShowProfileModal(true);
  };

  const handleLogoutClick = () => {
    setShowLogoutConfirm(true);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <div className="navbar-container">
        <div className="app-name">
          <span style={{ fontWeight: 'bold', color: '#FFC107' }}>Bee</span>
          <span style={{ color: theme === 'dark' ? '#ffffff' : '#000000' }}>Trading</span>
        </div>

        <div className="user-info">
          <span className="user-name">
            {user.name} {user.lastname}
          </span>
        </div>
        <div className="hexagon-grid">
          <div className={`hexagon profile ${menuOpen ? '' : 'hidden'}`}
            onClick={handleProfileClick} />
          <div className={`hexagon ${menuOpen ? 'close' : 'menu'}`}
            onClick={toggleMenu} />
          <div className={`hexagon ${theme === 'dark' ? 'sun' : 'moon'} ${menuOpen ? '' : 'hidden'}`}
            onClick={toggleTheme} />
          <div className={`hexagon logout ${menuOpen ? '' : 'hidden'}`}
            onClick={handleLogoutClick} />
        </div>

      </div>

      {/* Modal de perfil de usuario */}
      <UserProfileModal show={showProfileModal} handleClose={() => setShowProfileModal(false)} user={user} />

      {/* Modal de confirmación de cierre de sesión */}
      <LogoutConfirmModal show={showLogoutConfirm} handleClose={() => setShowLogoutConfirm(false)} handleLogout={handleLogout} />
    </>
  );
};

export default Navbar;