import React, { useState } from 'react';
import MarketDataTable from './MarketDataTable';
import BookOffersTable from './BookOffersTable';
import TradesTable from './TradesTable';
import './tablas.css';
import { useDataWebSocket } from '../context/WebSocketContext';

const TablesContainer = React.memo(() => {

  const { symbol, marketData, todayData } = useDataWebSocket();

  const [showTables, setShowTables] = useState(true);

  return (
    <div className="tables-wrapper">
      {/* Botón de toggle */}
      <div
        className={`toggle-tables-btn ${!showTables ? 'toggle-show-tables left-arrow' : 'toggle-hide-tables right-arrow'}`}
        onClick={() => setShowTables(!showTables)}
      />
      <div className={`tables-container${showTables ? '' : '-hidden'}`}>
        <h6 className="table-title">{symbol}</h6>
        <MarketDataTable symbol={symbol} marketData={marketData} />
        <BookOffersTable bids={marketData?.bids} offers={marketData?.offers} />
        <TradesTable todayData={todayData} />
      </div>
    </div>
  );
});

export default React.memo(TablesContainer);