import React, { createContext, useContext, useState, useMemo } from 'react';

const IntervalContext = createContext();

export const IntervalProvider = ({ children }) => {
  const [selectedInterval, setSelectedInterval] = useState({ label: '5 minutos', value: '5min' });

  const value = useMemo(
    () => ({ selectedInterval, setSelectedInterval }),
    [selectedInterval]
  );

  return <IntervalContext.Provider value={value}>{children}</IntervalContext.Provider>;
};

export const useInterval = () => useContext(IntervalContext);