import React, { useState } from 'react';
import './chartTools.css';
import { useStockUpdates, useStockValues } from '../context/StockContext';

const ChartTools = React.memo(() => {
  const { activeButton, showAnnotations, currentPrice } = useStockValues();
  const { setActiveButton, setShowAnnotations, toggleCurrentPrice } = useStockUpdates();
  const [submenuOpen, setSubmenuOpen] = useState(null);
  const [showTools, setShowTools] = useState(true);

  const menuSections = {
    annotations: [
      { title: 'Label', className: 'highcharts-label-annotation label-btn', button: 'label-btn' },
      { title: 'Ellipse', className: 'highcharts-ellipse-annotation ellipse-btn', button: 'ellipse-btn' },
      { title: 'Circle', className: 'highcharts-circle-annotation circle-btn', button: 'circle-btn' },
      { title: 'Rectangle', className: 'highcharts-rectangle-annotation rectangle-btn', button: 'rectangle-btn' },
    ],
    lines: [
      { title: 'Segment', className: 'highcharts-segment segment-btn', button: 'segment-btn' },
      { title: 'Arrow Segment', className: 'highcharts-arrow-segment arrow-segment-btn', button: 'arrow-segment-btn' },
      { title: 'Ray', className: 'highcharts-ray ray-btn', button: 'ray-btn' },
      { title: 'Arrow Ray', className: 'highcharts-arrow-ray arrow-ray-btn', button: 'arrow-ray-btn' },
    ],
    trendLines: [
      { title: 'Line', className: 'highcharts-infinity-line infinity-line-btn', button: 'infinity-line-btn' },
      { title: 'Arrow Line', className: 'highcharts-arrow-infinity-line arrow-infinity-line-btn', button: 'arrow-infinity-line-btn' },
      { title: 'Horizontal Line', className: 'highcharts-horizontal-line horizontal-line-btn', button: 'horizontal-line-btn' },
      { title: 'Vertical Line', className: 'highcharts-vertical-line vertical-line-btn', button: 'vertical-line-btn' },
    ],
    elliottWaves: [
      { title: 'Elliott 3', className: 'highcharts-elliott3 elliott3-btn', button: 'elliott3-btn' },
      { title: 'Elliott 5', className: 'highcharts-elliott5 elliott5-btn', button: 'elliott5-btn' },
      { title: 'Crooked 3', className: 'highcharts-crooked3 crooked3-btn', button: 'crooked3-btn' },
      { title: 'Crooked 5', className: 'highcharts-crooked5 crooked5-btn', button: 'crooked5-btn' },
    ],
    measures: [
      { title: 'Measure XY', className: 'highcharts-measure-xy measure-xy-btn', button: 'measure-xy-btn' },
      { title: 'Measure X', className: 'highcharts-measure-x measure-x-btn', button: 'measure-x-btn' },
      { title: 'Measure Y', className: 'highcharts-measure-y measure-y-btn', button: 'measure-y-btn' },
    ],
    fibonacci: [
      { title: 'Fibonacci', className: 'highcharts-fibonacci fibonacci-btn', button: 'fibonacci-btn' },
      { title: 'Fibonacci Time Zones', className: 'highcharts-fibonacci-time-zones fibonacci-time-zones-btn', button: 'fibonacci-time-zones-btn' },
      { title: 'Pitchfork', className: 'highcharts-pitchfork pitchfork-btn', button: 'pitchfork-btn' },
    ],
    channels: [
      { title: 'Parallel Channel', className: 'highcharts-parallel-channel parallel-channel-btn', button: 'parallel-channel-btn' },
      { title: 'Time Cycles', className: 'highcharts-time-cycles time-cycles-btn', button: 'time-cycles-btn' },
    ],
    verticalTools: [
      { title: 'Vertical Counter', className: 'highcharts-vertical-counter vertical-counter-btn', button: 'vertical-counter-btn' },
      { title: 'Vertical Label', className: 'highcharts-vertical-label vertical-label-btn', button: 'vertical-label-btn' },
      { title: 'Vertical Arrow', className: 'highcharts-vertical-arrow vertical-arrow-btn', button: 'vertical-arrow-btn' },
    ],
    flags: [
      { title: 'Flag Circle', className: 'highcharts-flag-circlepin flag-circlepin-btn', button: 'flag-circlepin-btn' },
      { title: 'Flag Diamond', className: 'highcharts-flag-diamondpin flag-diamondpin-btn', button: 'flag-diamondpin-btn' },
      { title: 'Flag Square', className: 'highcharts-flag-squarepin flag-squarepin-btn', button: 'flag-squarepin-btn' },
      { title: 'Flag Simple', className: 'highcharts-flag-simplepin flag-simplepin-btn', button: 'flag-simplepin-btn' },
    ],
  };

  const toggleSubmenu = (section) => {
    setSubmenuOpen(submenuOpen === section ? null : section);
  };

  const getActiveClassName = (items) => {
    const activeItem = items.find((item) => item.button === activeButton);
    return activeItem ? activeItem.button + ' active' : items[0].button;
  };

  return (
    <div className="chart-tools-wrapper">
      {/* Botón de toggle */}
      <div
        className={`toggle-tools-btn ${!showTools ? 'toggle-show-tools right-arrow' : 'toggle-hide-tools left-arrow'}`}
        onClick={() => setShowTools(!showTools)}
      />
      <div className={`chart-tools-container${showTools ? ' highcharts-stocktools-wrapper highcharts-bindings-container highcharts-bindings-wrapper' : '-hidden'}`}>
        {Object.entries(menuSections).map(([sectionKey, items]) => (
          <div key={sectionKey} className="menu-section">
            {/* Botón principal dinámico */}
            <div
              className={`hex-item ${submenuOpen === sectionKey ? 'close-submenu' : getActiveClassName(items)}`}
              title={submenuOpen === sectionKey ? 'Close Menu' : items[0].title}
              onClick={() => toggleSubmenu(sectionKey)}
            />
            {/* Submenú */}
            {submenuOpen === sectionKey && (
              <div className="submenu">
                {items.map(({ title, className, button }) => (
                  <div
                    key={button}
                    className={`submenu-item ${className}`}
                    title={title}
                    onClick={() => {
                      setActiveButton(button);
                      setSubmenuOpen(null); // Cierra el submenú al seleccionar
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        ))}

        {/* Botones adicionales */}
        <div
          className={`hex-item current-price-btn-${currentPrice ? 'show active' : 'hide'}`}
          title="Current Price Indicator"
          onClick={toggleCurrentPrice}
        />
        <div
          className={`hex-item highcharts-toggle-annotations annotations-btn-${showAnnotations ? 'show active' : 'hide'}`}
          title="Toggle Annotations"
          onClick={setShowAnnotations}
        />
      </div>

    </div>
  );
});

export default ChartTools;