import React, { useRef, useEffect, useState } from 'react';

import Highcharts, { chart } from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import StockTools from 'highcharts/modules/stock-tools';
import Indicators from 'highcharts/indicators/indicators-all';
import Annotations from 'highcharts/modules/annotations-advanced';
import PriceIndicator from 'highcharts/modules/price-indicator';
import HeikinAshi from 'highcharts/modules/heikinashi';
import HollowCandlestick from 'highcharts/modules/hollowcandlestick';



import 'highcharts/css/annotations/popup.css';


import { useChart } from '../context/ChartContext';
import { useStockUpdates } from '../context/StockContext';

import ChartHeader from './ChartHeader';
import ChartTools from './ChartTools'
import './chart.css';




StockTools(Highcharts);
Indicators(Highcharts);
Annotations(Highcharts);
PriceIndicator(Highcharts);

const ChartHigh = React.memo(() => {

    const { chartRef, updateChart } = useChart();
    const { setActiveButton } = useStockUpdates();
    const [seriesList, setSeriesList] = useState([]);


    const options = {
        chart: {
            events: {
                addSeries: function (e) {
                    if (e.options.type === 'flags') {
                        setActiveButton(null);
                    }
                }
            },
        },
        yAxis: [{
            height: '100%',
            labels: {
                align: 'left',
            }
        },
        {
            top: '85%',
            height: '15%',
            gridLineWidth: 0,
            labels: {
                enabled: false,
            },
        }],
        series: [{
            type: 'candlestick',
            name: 'Precio',
            id: 'price',
            data: [],
            tooltip: {
                valueDecimals: 2
            },
            yAxis: 0
        },
        {
            type: 'column',
            name: 'Volumen',
            id: 'volume',
            data: [],
            yAxis: 1
        }],
        stockTools: {
            gui: {
                enabled: false
            },
        },
        accessibility: {
            enabled: false
        },
        navigation: {
            annotationsOptions: {
                events: {
                    add: function (e) {
                        console.log(chartRef.current.chart);
                        
                        setActiveButton(null);
                    }
                }
            }
        },
        scrollbar: { enabled: false },
        tooltip: {
            shared: true, // Comparte un solo tooltip para ambas series
            useHTML: true, // Permite HTML personalizado
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo semitransparente
            borderColor: 'transparent', // Borde transparente para un estilo limpio
            style: {
                color: '#fff', // Texto blanco para buen contraste
                fontSize: '13px'
            },
        },
    };

    useEffect(() => {
        updateChart();
    });

    return (
        <>
            <ChartTools />
            <div className="chart-container">
                <ChartHeader />
                <div className='chart-high'>
                    <HighchartsReact
                        ref={chartRef}
                        highcharts={Highcharts}
                        constructorType={'stockChart'}
                        options={options}
                        containerProps={{ style: { height: '99%' } }}
                    />
                </div>
            </div>
        </>
    );
});

export default ChartHigh;