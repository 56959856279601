import React, { createContext, useState, useContext, useMemo } from 'react';
import { useChart } from './ChartContext';

// Crear dos contextos separados
const StockValueContext = createContext();
const StockUpdateContext = createContext();

// Proveedor del contexto
export const StockProvider = ({ children }) => {

    const { chartRef } = useChart();

    const [activeButton, setActiveButton] = useState(null);
    const [showAnnotations, setShowAnnotations] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [currentPrice, setCurrentPrice] = useState(false);

    const toggleCurrentPrice = () => {
      if (!chartRef.current) return; // Verificar si el gráfico está disponible
  
      const chart = chartRef.current.chart;
      
      // Alternar el estado actual
      setCurrentPrice((prev) => {
        const newState = !prev; // Cambia el estado actual
  
        // Aplicar los cambios al gráfico
        const series = chart.series[0];
        series.update({
          lastPrice: {
            enabled: newState, // Usa el nuevo estado
            color: 'red',
          },
          lastVisiblePrice: {
            enabled: newState,
            label: {
              enabled: newState,
              style: {
                color: 'white',
                fontWeight: 'bold',
              },
              backgroundColor: 'black',
            },
          },
        });
  
        return newState; // Devuelve el nuevo estado para actualizar currentPrice
      });
    };

    // Memorizar los valores para evitar renders innecesarios
    const stockValues = useMemo(
        () => ({ activeButton, showAnnotations, isFullScreen, currentPrice }),
        [activeButton, showAnnotations, isFullScreen, currentPrice]
    );

    const stockUpdates = useMemo(
        () => ({ setActiveButton, setShowAnnotations, setIsFullScreen, toggleCurrentPrice }),
        []
    );
    
    return (
        <StockValueContext.Provider value={stockValues}>
            <StockUpdateContext.Provider value={stockUpdates}>
                {children}
            </StockUpdateContext.Provider>
        </StockValueContext.Provider>
    );
};

// Hooks personalizados para consumir los valores
export const useStockValues = () => useContext(StockValueContext);
export const useStockUpdates = () => useContext(StockUpdateContext);