import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  
  const [showSelectFutureModal, setShowSelectFutureModal] = useState(false);
  const [showCompareFuturesModal, setShowCompareFuturesModal] = useState(false);
  const [showSavedChartsModal, setShowSavedChartsModal] = useState(false);
  const [showChangeColorsModal, setShowChangeColorsModal] = useState(false);

  const handleShowModal = (modalName) => {
    switch (modalName) {
      case 'SelectFutureModal':
        setShowSelectFutureModal(true);
        break;
      case 'CompareFuturesModal':
        setShowCompareFuturesModal(true);
        break;
      case 'SavedChartsModal':
        setShowSavedChartsModal(true);
        break;
      case 'ChangeColorsModal':
        setShowChangeColorsModal(true);
        break;
      default:
        break;
    }
  };

  const handleCloseModal = (modalName) => {
    switch (modalName) {
      case 'SelectFutureModal':
        setShowSelectFutureModal(false);
        break;
      case 'CompareFuturesModal':
        setShowCompareFuturesModal(false);
        break;
      case 'SavedChartsModal':
        setShowSavedChartsModal(false);
        break;
      case 'ChangeColorsModal':
        setShowChangeColorsModal(false);
        break;
      default:
        break;
    }
  };

  return (
    <ModalContext.Provider
      value={{
        showSelectFutureModal,
        showCompareFuturesModal,
        showSavedChartsModal,
        showChangeColorsModal,
        handleShowModal,
        handleCloseModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);