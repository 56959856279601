import React, { createContext, useRef, useContext, useEffect } from 'react';
import { getDataFromIndexedDB } from '../../utils/indexedDB';
import { useTheme } from './ThemeContext';

export const ChartContext = createContext();

export const ChartProvider = ({ children }) => {

  const chartRef = useRef(null);
  const { theme } = useTheme();
  const themeColors = {
    light: {
      background: '#FFFFFF',
      upCandle: '#00FF00',
      downCandle: '#FF0000',
      linesColors: '#E0E0E0',
      priceColor: '#000000',
      volumeColor: '#4682B4',
      timeColor: '#000000',
    },
    dark: {
      background: '#1E1E1E',
      upCandle: '#00FF00',
      downCandle: '#FF4500',
      linesColors: '#444444',
      priceColor: '#FFFFFF',
      volumeColor: '#FF6347',
      timeColor: '#CCCCCC',
    },
  };

  const updateData = async (symbol, interval) => {
    try {

      // Obtener los datos de IndexedDB para el símbolo y el intervalo seleccionado
      const newData = await getDataFromIndexedDB(symbol, interval);

      // Verificar si hay datos nuevos y actualizar el gráfico
      if (chartRef.current && newData.length > 0) {
        const chart = chartRef.current.chart;
        chart.series[0].setData(
          newData.map(item => [
            item.timestamp,
            item.open,
            item.high,
            item.low,
            item.close,
          ]),
          true // Redibuja el gráfico
        );
        chart.series[1].setData(newData.map(item => [item.timestamp, item.volume]), true);
      } else {
        console.log("No hay datos para el intervalo seleccionado.");
      }
    } catch (error) {
      console.error("Error al obtener datos de IndexedDB:", error);
    }
  };

  const updateColor = (field, value) => {
    if (!chartRef.current || !chartRef.current.chart) {
      console.warn('El gráfico aún no está inicializado');
      return;
    }

    const chart = chartRef.current.chart;

    switch (field) {
      case 'background':
        chart.update({
          chart: {
            backgroundColor: value,
          },
        });
        break;
      case 'upCandle':
        chart.series[0].update({
          upColor: value,
          upLineColor: value,
        });
        break;
      case 'downCandle':
        chart.series[0].update({
          color: value,
          lineColor: value,
        });
        break;
      case 'volumeColor':
        chart.series[1].update({
          color: value,
        });
        break;
      case 'linesColors':
        chart.yAxis.forEach((axis) => {
          axis.update({
            gridLineColor: value,
          });
        });
        break;
      case 'priceColor':
        chart.update({
          yAxis: {
            labels: {
              style: {
                color: value,
              },
            },
          },
        });
        break;
      case 'timeColor':
        chart.update({
          xAxis: {
            labels: {
              style: {
                color: value,
              },
            },
          },
        });
        break;
      default:
        console.warn(`No se reconoce el campo: ${field}`);
    }
  };



  const updateChart = () => {

    if (!chartRef.current || !chartRef.current.chart) {
      return;
    }
    const chart = chartRef.current.chart;

    const colors = themeColors[theme];

    chart.update({
      chart: {
        backgroundColor: colors.background,
      },
      xAxis: {
        labels: {
          style: {
            color: colors.timeColor,
          },
        },
      },
      yAxis: [
        {
          labels: {
            style: {
              color: colors.priceColor,
            },
          },
          gridLineColor: colors.linesColors,
        },
        {
          gridLineColor: colors.linesColors,
          labels: {
            style: {
              color: colors.priceColor,
            },
          },
        },
      ],
    });

    chart.series[0].update({
      upColor: colors.upCandle,
      upLineColor: colors.upCandle,
      color: colors.downCandle,
      lineColor: colors.downCandle,
    });

    chart.series[1].update({
      color: colors.volumeColor,
    });
  };

   // Estado inicial para los colores personalizados
   useEffect(() => {
    // Cambiar el tema del documento
    document.documentElement.className = theme;

    // Actualizar los colores del gráfico
    const colors = themeColors[theme];
    updateChart(colors);
}, [theme, updateChart]); // Dependencias: se ejecuta cuando cambia el tema o la función updateChart


  return (
    <ChartContext.Provider value={{ chartRef, updateData, updateColor, updateChart }}>
      {children}
    </ChartContext.Provider>
  );
};

export const useChart = () => useContext(ChartContext);