import React from 'react';

const MarketDataTable = ({ marketData }) => (
  <div className="table-market-data">
    <table>
      <tbody>
        {[
          ['Precio Ajuste', marketData?.settlement_price?.price],
          ['Último Cierre', marketData?.close_price?.price],
          ['Volumen Nominal', marketData?.nominal_volume ?? 'N/A'],
          ['Volumen Efectivo (ARS)', marketData?.effective_volume ?? 'N/A'],
          ['Interés Abierto', marketData?.open_interest?.size ?? 'N/A'],
          ['Volumen de Comercio', marketData?.trade_volume ?? 'N/A'],
          ['Precio de Apertura', marketData?.open_price ?? 'N/A'],
          ['Precio Más Alto', marketData?.high_price ?? 'N/A'],
          ['Último Precio', marketData?.last_price?.price ?? 'N/A'],
          ['Valor del Índice', marketData?.index_value ?? 'N/A'],
        ].map(([label, value], index) => (
          <tr key={index}>
            <td>{label}</td>
            <td>{typeof value === 'object' ? JSON.stringify(value) : value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default React.memo(MarketDataTable);