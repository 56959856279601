import { openDB } from 'idb';

const intervals = ['1min', '5min', '10min', '15min', '30min', '1h', '2h', '4h', '6h', '12h', '1d'];

// Abre la base de datos y crea los almacenes de objetos con índices para cada intervalo y today_trades
export const openDatabase = async () => {
    return await openDB('TradingDataDB', 1, {
        upgrade(db) {
            // Crear almacenes de objetos para cada intervalo
            intervals.forEach((interval) => {
                const storeName = `data_${interval}`;
                if (!db.objectStoreNames.contains(storeName)) {
                    const store = db.createObjectStore(storeName, { keyPath: 'symbol' });
                    store.createIndex('symbol', 'symbol', { unique: false });
                }
            });
        },
    });
};

// Almacena los datos en el almacén correspondiente, asegurándose de que no haya duplicados
export const storeDataInIndexedDB = async (storeName, symbol, data) => {
    console.log(`Símbolo recibido: ${symbol}`);
    
    try {
        const db = await openDatabase();
        const tx = db.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);

        // Validar que los datos no estén vacíos
        if (!data || data.length === 0) {
            console.warn(`No hay datos para almacenar en ${storeName} para ${symbol}`);
            return;
        }

        // Limpiar todos los datos previos del objectStore
        await store.clear();
        console.log(`Todos los datos en ${storeName} han sido eliminados.`);

        // Insertar los nuevos datos
        await store.put({ symbol, data });
        console.log(`Datos almacenados para ${symbol} en ${storeName}`);

        await tx.done;
    } catch (error) {
        console.error(`Error almacenando datos en ${storeName}:`, error);
    }
};

// Función para obtener datos de un intervalo específico usando symbol
export const getDataFromIndexedDB = async (symbol, interval) => {
    const db = await openDatabase();
    const storeName = `data_${interval}`;
    const tx = db.transaction(storeName, 'readonly');
    const store = tx.objectStore(storeName);
    const index = store.index('symbol');

    const records = await index.getAll(symbol);
    return records.length ? records[0].data : [];
};